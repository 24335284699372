import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import WorkItem from '../components/WorkItem'

const Work = () => (
  <StaticQuery
    query={graphql`
      query WorkQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/work/" }
            frontmatter: { published: { eq: true } }
          }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                url
                client
                clientUrl
                poster {
                  childImageSharp {
                    gatsbyImageData(quality: 50)
                  }
                }
                image {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                video {
                  publicURL
                }
                order
                gradient
                tags
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section>
        <div className="mx-5 lg:mx-20 xl:mx-40">
          <h2 className="text-4xl md:text-5xl font-bold tracking-tight">
            🖥 Work
          </h2>
        </div>
        <div className="mx-5 lg:mx-20 2xl:mx-40 mt-10 md:mt-20 xl:grid xl:grid-cols-2 xl:gap-x-16 2xl:gap-x-20">
          {data.allMarkdownRemark.edges
            .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
            .map(({ node }) => (
              <WorkItem key={node.id} data={node} />
            ))}
        </div>
      </section>
    )}
  />
)

export default Work
