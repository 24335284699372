import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import ProjectItem from './ProjectItem'

const Projects = () => (
  <StaticQuery
    query={graphql`
      query ProjectQuery {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/content/projects/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                title
                description
                url
                linkText
                order
                image {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                gradient
                tags
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section>
        <div className="mt-10 lg:mt-40 mx-5 lg:mx-20 xl:mx-40">
          <h2 className="text-4xl md:text-5xl font-bold tracking-tight">
            🍿 Projects
          </h2>
          {/* <p className="text-xl prose">
            I enjoy to tinker on ideas to learn new frameworks or APIs.
          </p> */}
        </div>
        <div className="mx-5 lg:mx-20 2xl:mx-40 mt-10 md:mt-20 xl:grid xl:grid-cols-2 xl:gap-x-16 2xl:gap-x-20">
          {data.allMarkdownRemark.edges
            .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
            .map(({ node }) => (
              <ProjectItem key={node.id} data={node} />
            ))}
        </div>
      </section>
    )}
  />
)

export default Projects
