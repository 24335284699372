import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Tools from '../components/Tools'
import Work from '../components/Work'
import Projects from '../components/Projects'

const WavingHand = styled.span`
  @keyframes wave {
    0% {
      transform: rotate(40deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(40deg);
    }
  }

  animation: wave 1.5s alternate infinite ease-in-out;
  transform: rotate(40deg);
  transform-origin: 80% 70%;
  display: inline-block;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="mt-24">
      <div className="my-20 mx-4 md:max-w-5xl xl:mx-auto md:mt-52 md:mb-60 text-center">
        <h1 className="text-5xl font-bold md:text-7xl xl:text-9xl tracking-tight mb-10">
          <WavingHand className="mr-6">👋</WavingHand>&nbsp;
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-pink-500">
            Hello there!
          </span>
        </h1>
        <p className="text-3xl md:text-4xl xl:text-5xl font-bold">
          <span className="leading-snug">
            I'm Marius Küng, a 🇨🇭 Frontend Developer building 📱 webapps and 🖥
            websites
          </span>
        </p>
      </div>
      <Tools />
      <Work />
      <Projects />
    </section>
  </Layout>
)

export default IndexPage
