import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import Video from './Video'
import Tags from './Tags'

const WorkItem = ({ data }) => {
  const { frontmatter } = data

  console.log(frontmatter)

  return (
    <div className="mt-20 xl:mt-0 xl:even:mt-60">
      <div className="shadow-2xl rounded-lg overflow-hidden">
        <pre></pre>
        {frontmatter.video && (
          <Video
            publicURL={frontmatter.video.publicURL}
            poster={frontmatter.poster}
          />
        )}
        {!frontmatter.video && (
          <GatsbyImage
            image={frontmatter.image.childImageSharp.gatsbyImageData}
            className="block shadow-2xl rounded-lg"
            alt={frontmatter.title}
          />
        )}
      </div>

      <a
        href={frontmatter.url}
        className="mt-4 md:mt-6 flex"
        target="_blank"
        rel="noopener noreferrer"
      >
        <h2
          className={`text-4xl md:text-5xl 2xl:text-6xl font-bold tracking-tight my-0 py-2 bg-clip-text text-transparent bg-gradient-to-r ${
            frontmatter.gradient
              ? frontmatter.gradient
              : 'from-yellow-200 to-green-600'
          }`}
        >
          {frontmatter.title}
        </h2>
        <div className="flex flex-col justify-end ml-2 py-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 md:h-12 md:w-12 transform"
            style={{ transform: 'rotate(-45deg)' }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </div>
      </a>
      <div className="mt-4">
        <Tags tags={frontmatter.tags} />
      </div>
    </div>
  )
}

export default WorkItem
