import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class Video extends React.Component {
  constructor(props) {
    super(props)

    this.videoRef = React.createRef()

    this.state = {
      isLoaded: false,
      isPlaying: false,
    }
  }

  play = () => {
    if (!this.state.isPlaying) {
      const video = this.videoRef.current
      this.setState({
        isLoaded: true,
        isPlaying: true,
      })
      // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      const playPromise = video.play()

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          })
      }
    }
  }

  stop = () => {
    if (this.state.isPlaying) {
      const video = this.videoRef.current
      video.pause()
      this.setState({
        isPlaying: false,
      })
    }
  }

  render() {
    const image = getImage(this.props.poster)
    return (
      <div
        className="relative hover:cursor-pointer"
        onClick={!this.state.isPlaying ? this.play : this.stop}
      >
        <div className={this.state.isPlaying ? 'hidden' : ''}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-white opacity-80 absolute inline-flex m-auto left-0 right-0 top-0 bottom-0 h-16 w-16 md:h-32 md:w-32 z-10"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
              clipRule="evenodd"
            />
          </svg>
          {!this.state.isLoaded && <GatsbyImage image={image} alt="" />}
        </div>

        <video
          className={`${
            this.state.isLoaded ? 'block' : 'hidden'
          } w-full h-full`}
          ref={this.videoRef}
          muted
          src={this.props.publicURL}
          type="video/mp4"
          loop
          playsInline
          preload="metadata"
        />
      </div>
    )
  }
}

export default Video
